
body{
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: url('./assets/imageedit_1_6317413733.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

h1{
  text-align: center;
  color: rgb(45, 45, 45);
  font-size: 72px;
  background-color: rgb(255, 227, 0);
}

button {
  text-align: center;
  color: rgb(45, 45, 45);
  font-size: 72px;
  background-color: rgb(255, 227, 0);
}

p{
  text-align: center;
  color: rgb(45, 45, 45);
  font-size: 32px;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

